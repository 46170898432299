.react-flow__node-custom, .react-flow__node-selecttemplate {
    font-size: 10px;
    width: 180px;
    background: #f5f5f6;
    color: #222;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
    border-radius: 2px;
  }
  
  .react-flow__node-custom .react-flow__handle, .react-flow__node-selecttemplate .react-flow__handle {
    width: 6px;
    height: 10px;
    border-radius: 2px;
    background-color: #778899;
  }
  
  .react-flow__node-custom .react-flow__handle-right, .react-flow__node-selecttemplate .react-flow__handle-right {
    top: 24px;
    right: -15px;
    width: 6px;
    height: 10px;
    border-radius: 2px;
    background-color: #778899;
  }

  .react-flow__node-buttons div.react-flow__handle-right{
    top: 24px;
    right: -15px;
    width: 6px;
    height: 10px;
    border-radius: 2px;
    background-color: #778899;
  }

  .react-flow__node-custombuttons .react-flow__handle-right{
    top: 24px;
    right: -15px;
    width: 6px;
    height: 10px;
    border-radius: 2px;
    background-color: #778899;
  }

  .react-flow__node-custom .react-flow__handle-left, .react-flow__node-selecttemplate .react-flow__handle-left {
    top: 24px;
    left: -15px;
    width: 6px;
    height: 10px;
    border-radius: 2px;
    background-color: #778899;
  }
  
  .react-flow__node.circle {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
  }
  
  .react-flow__node.annotation {
    border-radius: 0;
    text-align: left;
    background: white;
    border: none;
    line-height: 1.4;
    width: 225px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
  }
  
  .react-flow__node.annotation .react-flow__handle {
    display: none;
  }
  
  .custom-node__header {
    padding: 8px 10px;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .custom-node__body {
    padding: 10px;
  }
  
  .plain-node__header {
    padding: 8px 10px;
    border-bottom: 1px solid #e2e8f0;
    font-size: .6em;
  }
  
  .plain-node__body {
    padding: 10px;
    width: 250px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
  }

  .plain-node__body textarea{
    width: 100%;
    height: 100%;
    border: none;
    resize: none;
    overflow: auto;
    outline: none;
    font-size: 8px;
  }

  .plain-node__body span{
    font-size: 7px;
    font-style: italic;
  }

  .custom-node__select {
    position: relative;
    margin-bottom: 10px;
  }
  
  .custom-node__select select {
    width: 100%;
    margin-top: 5px;
    font-size: 10px;
  }

  /** list message node */

  .list-message-node{
    font-size: 10px;
    width: 280px;
    background: #f5f5f6;
    color: #222;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
    border-radius: 2px;
    padding: 2em;
    cursor: text !important;
  }

  .list-message-node div div button{
    float: right;
    width: 10px;
    height: 10px;
  }

  .list-message-node > button, .list-message-node > div >button{
    color:white;
    width: 100%;
    background-color: #3dc25d;
    text-transform: uppercase;
  }

  .list-message-node div div input{
    margin-top: 2%;
  }

  .list-message-node div div div small{
    display: block;
  }

    .react-flow__node-list .react-flow__handle-right{
    top:inherit;
    width: 6px;
    height: 10px;
    border-radius: 2px;
    background-color: #778899;
  }